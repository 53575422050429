const prod = {
  url: {
    API_URL: 'https://dev-api.easyquranicarabic.com/api/',
    CONTENT_URL: 'https://dev-api.easyquranicarabic.com/'
    //API_URL: "https://api.easyquranicarabic.com/api/",
    //CONTENT_URL: "https://api.easyquranicarabic.com/",
  },
};
const dev = {
  url: {
    API_URL: "https://localhost:44304/api/",
    CONTENT_URL: "https://localhost:44304/",
  },
};
export const API_URL = process.env.NODE_ENV === "development" ? dev.url.API_URL : prod.url.API_URL;
export const CONTENT_URL = process.env.NODE_ENV === "development" ? dev.url.CONTENT_URL : prod.url.CONTENT_URL;
